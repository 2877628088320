.confetti {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1000;
    pointer-events: none;

    --celeb-duration: 2000ms;
}
.confetti-piece {
    position: absolute;
    width: 10px;
    height: 30px;
    background: #ffd300;
    top: 0;
    opacity: 0;
}
.confetti-piece:nth-child(1) {
    left: 7%;
    transform: rotate(-40deg);
    animation: makeItRain var(--celeb-duration) infinite ease-out;
    animation-delay: 182ms;
    animation-duration: 1116ms;
    -webkit-transform: rotate(-40deg);
    -webkit-animation: makeItRain var(--celeb-duration) infinite ease-out;
    -webkit-animation-delay: 182ms;
    -webkit-animation-duration: 1116ms;
}
.confetti-piece:nth-child(2) {
    left: 14%;
    transform: rotate(4deg);
    animation: makeItRain var(--celeb-duration) infinite ease-out;
    animation-delay: 161ms;
    animation-duration: 1076ms;
    -webkit-transform: rotate(4deg);
    -webkit-animation: makeItRain var(--celeb-duration) infinite ease-out;
    -webkit-animation-delay: 161ms;
    -webkit-animation-duration: 1076ms;
}
.confetti-piece:nth-child(3) {
    left: 21%;
    transform: rotate(-51deg);
    animation: makeItRain var(--celeb-duration) infinite ease-out;
    animation-delay: 481ms;
    animation-duration: 1103ms;
    -webkit-transform: rotate(-51deg);
    -webkit-animation: makeItRain var(--celeb-duration) infinite ease-out;
    -webkit-animation-delay: 481ms;
    -webkit-animation-duration: 1103ms;
}
.confetti-piece:nth-child(4) {
    left: 28%;
    transform: rotate(61deg);
    animation: makeItRain var(--celeb-duration) infinite ease-out;
    animation-delay: 334ms;
    animation-duration: 708ms;
    -webkit-transform: rotate(61deg);
    -webkit-animation: makeItRain var(--celeb-duration) infinite ease-out;
    -webkit-animation-delay: 334ms;
    -webkit-animation-duration: 708ms;
}
.confetti-piece:nth-child(5) {
    left: 35%;
    transform: rotate(-52deg);
    animation: makeItRain var(--celeb-duration) infinite ease-out;
    animation-delay: 302ms;
    animation-duration: 776ms;
    -webkit-transform: rotate(-52deg);
    -webkit-animation: makeItRain var(--celeb-duration) infinite ease-out;
    -webkit-animation-delay: 302ms;
    -webkit-animation-duration: 776ms;
}
.confetti-piece:nth-child(6) {
    left: 42%;
    transform: rotate(38deg);
    animation: makeItRain var(--celeb-duration) infinite ease-out;
    animation-delay: 180ms;
    animation-duration: 1168ms;
    -webkit-transform: rotate(38deg);
    -webkit-animation: makeItRain var(--celeb-duration) infinite ease-out;
    -webkit-animation-delay: 180ms;
    -webkit-animation-duration: 1168ms;
}
.confetti-piece:nth-child(7) {
    left: 49%;
    transform: rotate(11deg);
    animation: makeItRain var(--celeb-duration) infinite ease-out;
    animation-delay: 395ms;
    animation-duration: 1200ms;
    -webkit-transform: rotate(11deg);
    -webkit-animation: makeItRain var(--celeb-duration) infinite ease-out;
    -webkit-animation-delay: 395ms;
    -webkit-animation-duration: 1200ms;
}
.confetti-piece:nth-child(8) {
    left: 56%;
    transform: rotate(49deg);
    animation: makeItRain var(--celeb-duration) infinite ease-out;
    animation-delay: 14ms;
    animation-duration: 887ms;
    -webkit-transform: rotate(49deg);
    -webkit-animation: makeItRain var(--celeb-duration) infinite ease-out;
    -webkit-animation-delay: 14ms;
    -webkit-animation-duration: 887ms;
}
.confetti-piece:nth-child(9) {
    left: 63%;
    transform: rotate(-72deg);
    animation: makeItRain var(--celeb-duration) infinite ease-out;
    animation-delay: 149ms;
    animation-duration: 805ms;
    -webkit-transform: rotate(-72deg);
    -webkit-animation: makeItRain var(--celeb-duration) infinite ease-out;
    -webkit-animation-delay: 149ms;
    -webkit-animation-duration: 805ms;
}
.confetti-piece:nth-child(10) {
    left: 70%;
    transform: rotate(10deg);
    animation: makeItRain var(--celeb-duration) infinite ease-out;
    animation-delay: 351ms;
    animation-duration: 1059ms;
    -webkit-transform: rotate(10deg);
    -webkit-animation: makeItRain var(--celeb-duration) infinite ease-out;
    -webkit-animation-delay: 351ms;
    -webkit-animation-duration: 1059ms;
}
.confetti-piece:nth-child(11) {
    left: 77%;
    transform: rotate(4deg);
    animation: makeItRain var(--celeb-duration) infinite ease-out;
    animation-delay: 307ms;
    animation-duration: 1132ms;
    -webkit-transform: rotate(4deg);
    -webkit-animation: makeItRain var(--celeb-duration) infinite ease-out;
    -webkit-animation-delay: 307ms;
    -webkit-animation-duration: 1132ms;
}
.confetti-piece:nth-child(12) {
    left: 84%;
    -webkit-transform: rotate(42deg);
    -webkit-animation: makeItRain var(--celeb-duration) infinite ease-out;
    -webkit-animation-delay: 464ms;
    -webkit-animation-duration: 776ms;
}
.confetti-piece:nth-child(13) {
    left: 91%;
    transform: rotate(-72deg);
    animation: makeItRain var(--celeb-duration) infinite ease-out;
    animation-delay: 429ms;
    animation-duration: 818ms;
    -webkit-transform: rotate(-72deg);
    -webkit-animation: makeItRain var(--celeb-duration) infinite ease-out;
    -webkit-animation-delay: 429ms;
    -webkit-animation-duration: 818ms;
}
.confetti-piece:nth-child(odd) {
    background: #7431e8;
}
.confetti-piece:nth-child(even) {
    z-index: 1;
}
.confetti-piece:nth-child(4n) {
    width: 5px;
    height: 12px;
    animation-duration: 2000ms;
    -webkit-animation-duration: 2000ms;
}
.confetti-piece:nth-child(3n) {
    width: 3px;
    height: 10px;
    animation-duration: 2500ms;
    animation-delay: var(--celeb-duration);
    -webkit-animation-duration: 2500ms;
    -webkit-animation-delay: var(--celeb-duration);
}
.confetti-piece:nth-child(4n-7) {
    background: red;
}
@keyframes makeItRain {
    from {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    to {
        transform: translateY(100vh);
        -webkit-transform: translateY(100vh);
    }
}
@-webkit-keyframes makeItRain {
    from {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    to {
        transform: translateY(100vh);
        -webkit-transform: translateY(100vh);
    }
}
