.home-main__section {
    position: relative;
    height: 200vh;
    width: 100%;
}
.home-main__container {
    width: 100%;
    height: 100vh;
    position: fixed;
    /* top: 0; */
}
.home-main__container:not(.static) {
    top: 0;
}
.home-main__container.static {
    bottom: 0px;
    position: absolute;
}
.home-main__content {
    max-width: 500px;
}
.home-main__title,
.home-main__text {
    position: relative;
    transform: translateY(200px);
    opacity: 0;
}
.home-main__title span {
    background: linear-gradient(45deg, #9d316e, #de2d3e);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.home-main__title .tip {
    content: "";
    position: absolute;
    height: var(--font-size-h3);
    width: 7rem;
    bottom: 23%;
    /* left: 52%; */
    right: 0px;
    background: linear-gradient(45deg, #9d316e, #de2d3e);
}
.home-main__text span {
    background: linear-gradient(-45deg, #0947db, #07d89d);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.home-secondary__container {
    background: linear-gradient(45deg, #9d316e, #de2d3e);
    /* background: linear-gradient(-45deg, #0947db, #07d89d); */
    position: relative;
    /* z-index: 90; */
}

.arrow {
    z-index: 100;
    position: fixed;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}
.arrow span {
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 3px solid #06a8ff;
    border-right: 3px solid #06a8ff;
    border-image-slice: 1;
    border-width: 0px 3px 3px 0px;
    border-image-source: linear-gradient(-45deg, #0947db, #07d89d);
    /* border-image-source: linear-gradient(-45deg, rgb(157, 49, 110), rgb(222, 45, 62)); */
    transform: rotate(45deg);
    /* margin: -10px; */
    animation: animate 2s infinite;
    /* border-image-source: linear-gradient(45deg, #9d316e, #de2d3e); */
}
.arrow span:nth-child(2) {
    animation-delay: -0.2s;
}
.arrow span:nth-child(3) {
    animation-delay: -0.4s;
}
@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}
.arrow-overlay {
    transform: translate(50%, -50%);
}
