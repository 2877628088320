.home__wise-1 .home-wise__triangle {
    /* background: #0003; */
    --triangle-gradient-2: var(--color-purple-dark-1);
    --triangle-gradient-1: var(--color-rose-dark-1);
}

.home__wise-1 .triangle {
    stroke-dashoffset: 924.9;
    stroke-dasharray: 924.9;
    fill: none;
    /* stroke: var(--color-purple-dark-1); */
    stroke: url(#linear-gradient);
    stroke-width: 24;
}

.home__wise-1 #linear-gradient stop {
    transition: all 1s ease-out;
}

.hide {
    fill: var(--color-primary-container);
}

.triangle-text {
    fill: var(--color-text-primary);
    font-size: 36px;
    font-family: "cairo";
    /* font-weight: var(--font-w-medium); */
}

.home__wise-1 .triangle-text__container-1 {
    transform: translateX(-90px);
}

.home__wise-1 .triangle-text__container-2 {
    transform: translateX(90px);
}

.home-wise__rect {
    position: absolute;
    padding: 10rem 3rem;
    border-radius: 100rem;
    opacity: 0.3;
    z-index: -10;
    transition: all 1s ease-out;
}
.home-wise__rect:nth-child(1) {
    background-color: theme("colors.teal.500");
    left: 2rem;
    top: 3rem;
}
.home-wise__rect:nth-child(2) {
    bottom: -15rem;
    right: 3rem;
    background-color: theme("colors.rose.500");
}
.darkmode .home-wise__rect {
    opacity: 0.15;
}
.home-wise-triangle__contianer {
    max-width: 50%;
    margin: 0 auto;
    /* background: linear-gradient(0deg, #00e 0%, #00e 50%, #0e0 50%, #0e0 100%); */
}

.home__wise-2 {
    overflow: hidden;
}

.home-wise__triangle2 {
    /* background: #0003; */
    --triangle-gradient-2: var(--color-purple-dark-1);
    --triangle-gradient-1: var(--color-rose-dark-1);
}

.home-wise__triangle2 .triangle {
    stroke-dashoffset: 803.6;
    stroke-dasharray: 803.6;
    fill: none;
    /* stroke: var(--color-purple-dark-1); */
    /* stroke: var(--color-teal-dark-1); */
    stroke: theme("colors.teal.500");
    stroke-width: 24;
}

#linear-gradient2 stop {
    transition: all 1s ease-out;
}

.hide {
    fill: var(--color-primary-container);
    /* opacity: .5; */
}

.triangle-text {
    fill: var(--color-text-primary);
    font-size: 36px;
    font-family: "cairo";
    /* font-weight: var(--font-w-medium); */
}

.triangle-text__container-1 {
    transform: translateX(-90px);
}

.triangle-text__container-2 {
    transform: translateX(90px);
}
